<template>
    <div class="page-body wrapper-fixed">

        <div class="login-control d-flex">
            <router-link :to="{name : 'login'}" class="back-button">
                <div class="back-button__icon">
                    <i class="eicon e-back"></i>
                </div>
                <div class="back-button__text">{{ $t("back to login")}}</div>
            </router-link>
            <language-switcher class="ml-auto"></language-switcher>
        </div>
        <div class="col-md-6 login-thumbnail">
            <a href="#" class="site-logo">
                <site-logo-s-v-g/>
            </a>
            <img src="/img/Repeat Grid 3.png" alt="" class="login-page__background"/>
        </div>
        <div class="col-md-6 login-content">
            <div class="text-center">
                <h4 class="page-title">{{ $t("Don't worry!")}}</h4>
                <p class="page-description">{{ $t("You can re-enter into your account by resetting the password")}}</p>
            </div>

            <div class="login-box">
                <template>
                    <div class="alert fade show alert-success" role="alert" v-if="alert">
                        <button type="button" class="close" @click="hideAlert()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {{ successMessage }}
                    </div>
                    <div class="alert fade show alert-danger" role="alert" v-if="alertError">
                        <button type="button" class="close" @click="hideErrorAlert()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {{ errorMessage }}
                    </div>
                </template>

                <form action="" @submit.prevent="submitEmail">
                    <div class="form-group">
                        <label class="text-capitalize">{{ $t("email address")}}</label>
                        <input class="form-control" v-model="form.email"
                               :class="{'has-error' : errors.email}"  type="text"
                               :placeholder="$t('enter your email address')" />
                        <error-message :message="errors.email" />
                    </div>
                    <submit-button :click="submitEmail" :block="true" :loading="isLoading" >{{ $t("send reset link")}}</submit-button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import SiteLogoSVG from "../components/SiteLogoSVG";
    const LanguageSwitcher = () => import("../components/dropdown/LanguageSwitcher.vue");
    import client from "../app/api/Client";
    const ErrorMessage = () => import("../components/common/ErrorMessage.vue");

    export default {
        components: {
            SiteLogoSVG,
            ErrorMessage,
            LanguageSwitcher,
        },
        data() {
            return {
                form: {
                    email: "",
                },
                errors: {
                    email: "",
                },
                isLoading: false,
                successMessage : this.$t("We have e-mailed your password reset link!"),
                alert: false,
                alertError: false,
                errorMessage : '',
            }
        },
        methods: {
            submitEmail() {
                this.alert = false;
                this.alertError = false;
                this.errorMessage = '';
                if (this.validateForm()) {
                    this.isLoading = true;
                    client().post('/forgot-password', this.form)
                        .then(({data: {message}}) => {
                            this.$toast.success(message);
                            this.alert =true;
                            this.isLoading = false;
                            this.form.email ='';
                        })
                        .catch(error => {
                            let errors = error.response.data.message;
                            if (typeof errors === 'object') {
                                this.errors = _helper.serializeValidationMessage(errors);
                                this.$toast.error('Invalid Request');
                            } else {
                                this.alertError = true;
                                this.errorMessage = errors;
                                this.$toast.error(errors);
                            }
                            this.isLoading = false;
                        });
                }
            },
            hideAlert(){
              this.alert = false;
            },
            hideErrorAlert(){
              this.alertError = false;
            },

            validateForm() {
                this.errors.email = "";
                this.errors.password = "";

                if (this.form.email.length < 1) {
                    this.errors.email = this.$t('Email field is required');
                    return false;
                }

                if (!_helper.validateEmail(this.form.email)) {
                    this.errors.email = this.$t('Please type a valid email address');
                    return false;
                }
                return true;
            }
        }
    }
</script>


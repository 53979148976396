<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.27 54.729" class="site-logo">
        <g id="Easy.jobs_logo" transform="translate(-211.1 -294.1)">
            <g id="Group_2" data-name="Group 2" transform="translate(211.1 294.1)">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="2.743" cy="2.743" r="2.743" transform="translate(48.803 44.948)" fill="#597dfc"/>
                <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M369.925,468.8a2.225,2.225,0,0,0-2.225,2.225h0a2.225,2.225,0,1,0,4.451,0h0A2.209,2.209,0,0,0,369.925,468.8Z"
                        transform="translate(-327.177 -423.594)"
                        fill="#597dfc"
                />
                <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M430.035,492.9A1.038,1.038,0,0,0,429,493.935h0a1.035,1.035,0,1,0,2.07,0h0A1.038,1.038,0,0,0,430.035,492.9Z"
                        transform="translate(-372.615 -441.457)"
                        fill="#597dfc"
                />
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.449" cy="1.449" r="1.449" transform="translate(45.802 51.831)" fill="#597dfc"/>
                <g id="Group_1" data-name="Group 1">
                    <path id="Path_3" data-name="Path 3" d="M287.27,333.9a3.027,3.027,0,0,0,.828.1h.052a2.98,2.98,0,1,0-1.346-.336Z" transform="translate(-266.025 -319.302)" fill="#597dfc"/>
                    <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M252.218,328A22.112,22.112,0,0,0,237.8,294.592a22.87,22.87,0,0,0-4.632-.492,22.26,22.26,0,0,0-5.486,43.783c-1.578-.75-12.136-8-12.007-22.176.078-9.134,7.763-16.535,16.9-16.846a17.5,17.5,0,0,1,7.349,33.64c-1.346.44-6,1.5-9.859-3.752a14.153,14.153,0,0,1-2.122-9.109,4.234,4.234,0,0,1,4.166-4.011,2.226,2.226,0,0,1,.414.026A4.116,4.116,0,0,1,236.614,320a16.027,16.027,0,0,1-1.5,5.926l-.1.207c-.129.285-.285.621-.414.88a1.5,1.5,0,0,0-.078.957,1.413,1.413,0,0,0,1.6,1.035h.026a1.441,1.441,0,0,0,.932-.6,16.624,16.624,0,0,0,3.364-9.341,7.743,7.743,0,0,0-2.148-5.512,8.147,8.147,0,0,0-5.848-2.277h-.492a9.851,9.851,0,0,0-3.209.7h0a8.135,8.135,0,0,0-5.253,7.22,16.624,16.624,0,0,0,4.554,13.559,14.607,14.607,0,0,0,1.811,1.76h0a13.892,13.892,0,0,0,2.458,1.6,8.63,8.63,0,0,0,1.294.647.09.09,0,0,1,.052.026h0v-.026a11.056,11.056,0,0,0,2.562.7h.026a10.874,10.874,0,0,0,5.771-.776,22.292,22.292,0,0,0,4.114-2.355l3.6,3.364a5.378,5.378,0,0,1,8.565,1.786,4.134,4.134,0,0,1,2.743-2.536Z"
                            transform="translate(-211.1 -294.1)"
                            fill="#597dfc"
                    />
                </g>
            </g>
            <g id="Group_3" data-name="Group 3" transform="translate(279.44 306.65)">
                <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M556.114,369.171a6.751,6.751,0,0,0-4.425-1.371,16.718,16.718,0,0,0-3.364.362,7.859,7.859,0,0,0-2.717,1.009l-.129.078v3l.414-.336a6.587,6.587,0,0,1,2.3-1.113,10.17,10.17,0,0,1,2.743-.388c2.665,0,3.959,1.087,3.959,3.364v1.475a10.56,10.56,0,0,0-4.813-.957,6.878,6.878,0,0,0-4.5,1.371,5.345,5.345,0,0,0-1.682,4.373,4.341,4.341,0,0,0,1.941,3.985,8.39,8.39,0,0,0,4.5,1.165,14.868,14.868,0,0,0,5.356-.8,3.281,3.281,0,0,0,2.07-3.338V373.26A5.079,5.079,0,0,0,556.114,369.171Zm-5.2,13.4a5.8,5.8,0,0,1-3-.725,2.384,2.384,0,0,1-1.164-2.225,2.3,2.3,0,0,1,.983-2.044,5.055,5.055,0,0,1,2.976-.724,11.538,11.538,0,0,1,2.355.233,10.974,10.974,0,0,1,1.837.569V381.1a1.107,1.107,0,0,1-.828,1.139A10.214,10.214,0,0,1,550.912,382.576Z"
                        transform="translate(-526.123 -361.279)"
                        fill="#1c2238"
                />
                <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M621.483,375.751c-.647-.285-1.5-.6-2.588-.957a17.186,17.186,0,0,1-2.018-.647,3.07,3.07,0,0,1-1.164-.776,1.687,1.687,0,0,1-.44-1.19,1.5,1.5,0,0,1,.88-1.371,4.528,4.528,0,0,1,2.3-.543,5.208,5.208,0,0,1,1.915.31c.569.207,1.371.6,2.432,1.113l.362.181v-2.9l-.155-.078c-1.035-.44-1.863-.776-2.458-.983a7.164,7.164,0,0,0-2.251-.311,7.769,7.769,0,0,0-2.795.518,4.924,4.924,0,0,0-2.2,1.6,4.337,4.337,0,0,0-.854,2.743,3.953,3.953,0,0,0,.828,2.614,4.855,4.855,0,0,0,1.863,1.4,26.355,26.355,0,0,0,2.847.932l.854.362a7.473,7.473,0,0,1,1.863.983,1.583,1.583,0,0,1,.518,1.294,1.888,1.888,0,0,1-.906,1.63,4.273,4.273,0,0,1-2.484.673,7.361,7.361,0,0,1-2.406-.362,15.956,15.956,0,0,1-2.639-1.294l-.388-.207v3.053l.13.078a14.421,14.421,0,0,0,2.588,1.061,11.511,11.511,0,0,0,2.872.336,7.294,7.294,0,0,0,2.872-.569,5.266,5.266,0,0,0,2.277-1.734,4.651,4.651,0,0,0,.88-2.9,4.082,4.082,0,0,0-.776-2.614A4.251,4.251,0,0,0,621.483,375.751Z"
                        transform="translate(-576.897 -361.131)"
                        fill="#1c2238"
                />
                <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M674.225,381.48l-4.917-12.68H666.1l6.78,16.406a11.471,11.471,0,0,1-1.371,2.872,2.352,2.352,0,0,1-1.967.906,3.124,3.124,0,0,1-1.294-.259l-.362-.155v2.691l.155.052a4.316,4.316,0,0,0,1.6.31,4.637,4.637,0,0,0,2.872-1.087,8.443,8.443,0,0,0,2.407-3.726l7.038-18.01H679.09Z"
                        transform="translate(-616.701 -362.02)"
                        fill="#1c2238"
                />
                <rect id="Rectangle_2" data-name="Rectangle 2" width="3.726" height="3.726" transform="translate(68.392 19.666)" fill="#1c2238"/>
                <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M766.486,385.956a5.017,5.017,0,0,1-.233,1.708,1.71,1.71,0,0,1-.854.906,4.239,4.239,0,0,1-1.941.362H763.2v2.691h.259a6.265,6.265,0,0,0,4.4-1.371,5.45,5.45,0,0,0,1.475-4.166V368.8h-2.821v17.156Z"
                        transform="translate(-688.675 -362.02)"
                        fill="#1c2238"
                />
                <rect id="Rectangle_3" data-name="Rectangle 3" width="3.079" height="3.053" transform="translate(77.682 0.88)" fill="#1c2238"/>
                <path
                        id="Path_9"
                        data-name="Path 9"
                        d="M809.808,367.7a7.29,7.29,0,0,0-5.667,2.2c-1.294,1.449-1.941,3.623-1.941,6.443a9.768,9.768,0,0,0,1.915,6.443,8.187,8.187,0,0,0,11.386-.052,9.571,9.571,0,0,0,1.993-6.417A9.868,9.868,0,0,0,815.6,370,7.238,7.238,0,0,0,809.808,367.7Zm4.865,8.643c0,4.089-1.578,6.107-4.839,6.107-3.234,0-4.813-1.993-4.813-6.107,0-4.037,1.578-6,4.813-6a4.385,4.385,0,0,1,3.7,1.5A7.66,7.66,0,0,1,814.673,376.343Z"
                        transform="translate(-717.583 -361.205)"
                        fill="#1c2238"
                />
                <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M883.083,349.147a10.455,10.455,0,0,0-2.122.285,17.4,17.4,0,0,0-1.941.543V342.6H876.2v20.391c0,2.3,2.251,3.467,6.7,3.467a8.116,8.116,0,0,0,5.46-1.992c1.527-1.32,2.329-3.674,2.355-7.013a9.086,9.086,0,0,0-1.915-6.107A7.368,7.368,0,0,0,883.083,349.147Zm4.658,8.332a8.361,8.361,0,0,1-1.113,4.684c-.7,1.087-2.07,1.656-4.063,1.656a8.488,8.488,0,0,1-2.588-.336c-.854-.259-.957-.725-.957-1.035V352.8a9.5,9.5,0,0,1,1.915-.75,9.081,9.081,0,0,1,2.148-.285,4.029,4.029,0,0,1,3.493,1.5A6.809,6.809,0,0,1,887.741,357.479Z"
                        transform="translate(-772.434 -342.6)"
                        fill="#1c2238"
                />
                <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M954.868,377.074a4.893,4.893,0,0,0-1.785-1.423c-.647-.285-1.5-.6-2.588-.957a17.167,17.167,0,0,1-2.018-.647,3.072,3.072,0,0,1-1.165-.776,1.687,1.687,0,0,1-.44-1.19,1.5,1.5,0,0,1,.88-1.371,4.528,4.528,0,0,1,2.3-.543,5.208,5.208,0,0,1,1.915.311c.543.207,1.371.6,2.432,1.113l.362.181v-2.9l-.155-.078c-1.035-.44-1.863-.776-2.458-.983a7.167,7.167,0,0,0-2.251-.31,7.766,7.766,0,0,0-2.795.517,4.923,4.923,0,0,0-2.2,1.6,4.338,4.338,0,0,0-.854,2.743,3.953,3.953,0,0,0,.828,2.614,4.854,4.854,0,0,0,1.863,1.4,26.331,26.331,0,0,0,2.846.932l.854.362a7.467,7.467,0,0,1,1.863.983,1.583,1.583,0,0,1,.518,1.294,1.888,1.888,0,0,1-.906,1.63,4.273,4.273,0,0,1-2.484.673,7.361,7.361,0,0,1-2.406-.362,15.961,15.961,0,0,1-2.64-1.294l-.388-.207v3.053l.129.078a14.414,14.414,0,0,0,2.588,1.061,11.513,11.513,0,0,0,2.872.336,7.3,7.3,0,0,0,2.872-.569,5.267,5.267,0,0,0,2.277-1.734,4.651,4.651,0,0,0,.88-2.9A4.049,4.049,0,0,0,954.868,377.074Z"
                        transform="translate(-822.69 -361.057)"
                        fill="#1c2238"
                />
                <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M489.794,377.038v-.259a10.6,10.6,0,0,0-1.682-6.5h0a6.416,6.416,0,0,0-5.589-2.484,6.9,6.9,0,0,0-5.408,2.2c-1.268,1.449-1.915,3.6-1.915,6.366,0,2.846.673,5.046,2.044,6.547a7.255,7.255,0,0,0,5.667,2.251A13,13,0,0,0,488.656,384l.155-.078v-3l-.388.233a10.136,10.136,0,0,1-5.382,1.346,4.7,4.7,0,0,1-3.674-1.449,6.17,6.17,0,0,1-1.294-4.037h0c0-.828.052-1.682.129-2.51v-.129a4.94,4.94,0,0,1,1.268-3.053,4.4,4.4,0,0,1,3.053-.906,4.274,4.274,0,0,1,3.131,1.035,4.679,4.679,0,0,1,1.164,2.924h-9.264v2.639h12.24Z"
                        transform="translate(-475.2 -361.279)"
                        fill="#1c2238"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "SiteLogoSVG"
}
</script>
